export default [
  {
    params: {
      meta: {
        requiresAuth: true,
          requiredRoles: ['super_admin','admin','admin_companies'],
        requiredModule: 1,
      },
      path: '/business/corporate-structure/admin-panel',
      name: 'business/corporate-structure/AdminPanel',
    },
    defaultComponentPath: 'ComporateStructure/AdminPanel',
    navs: true,
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin','admin_identity'],
        requiredModule: 1,
      },
      path: '/business/corporate-structure/identity',
      name: 'business/corporate-structure/Identity',
    },
    defaultComponentPath: 'ComporateStructure/Identity',
    navs: true,
  },
  {
    params: {
      meta: {
        requiresAuth: true,
          requiredRoles: ['super_admin','admin','admin_companies'],
        requiredModule: 1,
      },
      path: '/business/corporate-structure/companies',
      name: 'business/corporate-structure/Companies',
    },
    defaultComponentPath: 'ComporateStructure/Companies',
    navs: true,
  },
  {
    params: {
      meta: {
        requiresAuth: true,
          requiredRoles: ['super_admin','admin','admin_companies'],
        requiredModule: 1,
      },
      path: '/business/corporate-structure/companies/:companyId/users',
      name: 'business/corporate-structure/Users',
    },
    defaultComponentPath: 'ComporateStructure/CRUDs/Users',
    navs: true,
  },
  {
    params: {
      meta: {
        requiresAuth: true,
          requiredRoles: ['super_admin','admin','admin_companies'],
        requiredModule: 1,
      },
      path: '/business/corporate-structure/map',
      name: 'business/corporate-structure/Map',
    },
    defaultComponentPath: 'ComporateStructure/Map',
    navs: true,
  },
  {
    params: {
      meta: {
        requiresAuth: true,
          requiredRoles: ['super_admin','admin','user'],
        requiredModule: 0,
      },
      path: '/business/corporate-structure/my-company',
      name: 'business/corporate-structure/MyCompany',
    },
    defaultComponentPath: 'ComporateStructure/MyCompany',
    navs: true,
  },
]
