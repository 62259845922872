<template>
	<v-sheet :max-width="maxWidth" class="transparent">
		<!-- Navigation -->
		<v-list-item class="fixed-item app-bg">
			<v-list-item-content>
				<v-list-item-title class="title">Theme Settings</v-list-item-title>
			</v-list-item-content>
			<v-list-item-icon>
				<v-btn fab small class="neu-glow" @click="handleDrawer()">
					<v-icon>close</v-icon>
				</v-btn>
			</v-list-item-icon>
		</v-list-item>
		<v-sheet height="72" />
		<!-- Lang -->
		<v-sheet class="mx-3 mb-3 neu-glow-inset with-radius">
			<v-menu offset-y close-on-click>
				<template v-slot:activator="{ on }">
					<v-row style="align-items: center"
						><div class="mx-3 pa-3 text-h6">Language</div>
						<v-btn v-on="on" small fab class="ml-0">
							<v-avatar size="30">
								<v-img :src="currentLocaleImg"/>
							</v-avatar> </v-btn
					></v-row>
				</template>

				<v-list dense class="neu-glow">
					<v-list-item
						v-for="(item, index) in langs"
						:key="index"
						@click="handleInternationalization(item.value)"
					>
						<v-list-item-avatar tile class="with-radius" size="25">
							<v-img :src="item.img"/>
						</v-list-item-avatar>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-sheet>
		<!-- Visibility -->
		<v-sheet class="mx-3 mb-3 neu-glow-inset with-radius">
			<div class="pa-3 text-h6">Visibility</div>
			<v-divider/>
			<visibility />
		</v-sheet>
		<!-- Theme Builder -->
		<v-sheet class="mx-3 mb-3 neu-glow-inset with-radius">
			<div class="pa-3 text-h6">Theme Builder</div>
			<v-divider/>
			<theme />
		</v-sheet>

		<!-- Header -->
		<v-sheet class="mx-3 mb-3 neu-glow-inset with-radius">
			<div class="pa-3 text-h6">Header Setting</div>
			<v-divider/>
			<header-settings />
		</v-sheet>

		<!-- Sidebar -->
		<v-sheet class="mx-3 mb-3 neu-glow-inset with-radius">
			<div class="pa-3 text-h6">Sidebar Setting</div>
			<v-divider/>
			<sidenav-settings />
		</v-sheet>

		<!-- Footer -->
		<v-sheet class="mx-3 mb-3 neu-glow-inset with-radius">
			<div class="pa-3 text-h6">Footer Setting</div>
			<v-divider/>
			<footer-settings />
		</v-sheet>

		<!-- Language -->
		<!--<v-sheet class="mx-3 mb-3 neu-glow-inset with-radius">
			<div class="pa-3 text-h6">Language Selection</div>
			<v-divider></v-divider>
			<language-selection />
		</v-sheet>-->
	</v-sheet>
</template>
<script>
	import { mapGetters } from 'vuex'
	import Visibility from './Visibility'
	import SidenavSettings from './SidenavSettings'
	import FooterSettings from './FooterSettings'
	import HeaderSettings from './HeaderSettings'
	// import LanguageSelection from './LanguageSelection'
	import Theme from './Theme'
	import { availableLocale } from '@/config/locale'

	export default {
		data() {
			return {
				langs: availableLocale,
			}
		},
		props: {
			maxWidth: {
				type: [ String, Number ],
				default: '',
			},
			classes: {
				type: [ String, Array ],
				default: 'transparent',
			},
			handleDrawer: Function,
		},
		computed: {
			...mapGetters( [ 'locale' ] ),
			currentLocaleImg() {
				return this.langs.find( ( item ) => item.value === this.locale ).img
			},
		},
		components: {
			Visibility,
			SidenavSettings,
			// LanguageSelection,
			FooterSettings,
			HeaderSettings,
			Theme,
		},
		methods: {
			handleInternationalization( value ) {
				this.$store.dispatch( 'setLocale', value )
			},
		},
	}
</script>

<style scoped>
	.fixed-item {
		position: fixed;
		width: 100%;
		z-index: 1;
	}
</style>
