/* eslint-disable */
import LazyLoad from "@/utils/AsyncRouteHelper";
import vuse from "./routes/vuse";
import auth from "./routes/auth";
import accounting from "./routes/accounting";
import b2b from "./routes/b2b";
import balancePayable from "./routes/balancePayable";
import balanceReceivable from "./routes/balanceReceivable";
import banks from "./routes/banks";
import billing from "./routes/billing";
import budgets from "./routes/budgets";
import corporateStructure from "./routes/corporateStructure";
import crm from "./routes/crm";
import elearning from "./routes/elearning";
import embedded from "./routes/embedded";

import expansion from "./routes/expansion";
import fixedAssets from "./routes/fixedAssets";
import humanTalent from "./routes/humanTalent";
import mailing from "./routes/mailing";
import managementControl from "./routes/managementControl";
import marketingManagement from "./routes/marketingManagement";
import notifications from "./routes/notifications";
import polls from "./routes/polls";
import pos from "./routes/pos";
import projects from "./routes/projects";
import purchases from "./routes/purchases";
import referrals from "./routes/referrals";
import royalties from "./routes/royalties";
import stock from "./routes/stock";
import supervisionControl from "./routes/supervisionControl";
import tickets from "./routes/tickets";

const AppSidebar = () =>
  import(
    /* webpackChunkName: "Vusenavs", webpackPreload: true */ "@/layouts/App/Sidebar"
  );
const AppToolbar = () =>
  import(
    /* webpackChunkName: "Vusenavs", webpackPreload: true */ "@/layouts/App/Toolbar"
  );
const AppFooter = () =>
  import(
    /* webpackChunkName: "Vusenavs",  webpackPreload: true */ "@/layouts/App/Footer"
  );

const AllRoutes = [
  ...vuse,
  ...auth,
  ...accounting,
  ...b2b,
  ...balancePayable,
  ...balanceReceivable,
  ...banks,
  ...billing,
  ...budgets,
  ...corporateStructure,
  ...crm,
  ...elearning,
  ...embedded,
  ...expansion,
  ...fixedAssets,
  ...humanTalent,
  ...mailing,
  ...managementControl,
  ...marketingManagement,
  ...notifications,
  ...polls,
  ...pos,
  ...projects,
  ...purchases,
  ...referrals,
  ...royalties,
  ...stock,
  ...supervisionControl,
  ...tickets
];
const routes = AllRoutes.map((route) => {
  const { params, defaultComponentPath, navs } = route;
  const componentObj = navs
    ? {
        components: {
          default: LazyLoad(defaultComponentPath),
          sidebar: AppSidebar,
          header: AppToolbar,
          footer: AppFooter
        }
      }
    : {
        component: LazyLoad(defaultComponentPath)
      };
  return {
    ...params,
    ...componentObj
  };
});

export default [
  {
    path: "/",
    redirect: "/business/corporate-structure/my-company",
    meta: {
      requiresAuth: true,
      requiredRoles: [],
      requiredModule: 0
    }
  },
  ...routes
];
