// Authentication Pages

export default [
  /* {
    params: {
      meta: {
        layout: "auth",
        requiresAuth: true, requiredRoles: []
      },
      path: "/pages/authentication/login",
      name: "pages/authentication/LoginPage",
    },
    defaultComponentPath: "Pages/Authentication/Login/Login",
  },
  {
    params: {
      meta: {
        layout: "auth",
        requiresAuth: true, requiredRoles: []
      },
      path: "/pages/authentication/forgot-password",
      name: "pages/authentication/ForgotPasswordPage",
    },
    defaultComponentPath: "Pages/Authentication/ForgotPassword/ForgotPassword",
  },
  {
    params: {
      meta: {
        layout: "auth",
        requiresAuth: true, requiredRoles: []
      },
      path: "/pages/authentication/signup",
      name: "pages/authentication/SignupPage",
    },
    defaultComponentPath: "Pages/Authentication/Signup/Signup",
  },
  {
    params: {
      meta: {
        layout: "auth",
        requiresAuth: true, requiredRoles: []
      },
      path: "/pages/authentication/lock-screen",
      name: "pages/authentication/LockScreenPage",
    },
    defaultComponentPath: "Pages/Authentication/LockScreen/LockScreen",
  }, */
  {
    params: {
      meta: {
        layout: "full",
        requiresAuth: true,
        requiredRoles: [],
        requiredModule: 0
      },
      path: "/pages/coming-soon",
      name: "ComingSoon"
    },
    defaultComponentPath: "Pages/ComingSoon"
  },
  {
    params: {
      meta: {
        layout: "full",
        requiresAuth: true,
        requiredRoles: [],
        requiredModule: 0
      },
      path: "/pages/under-maintenance",
      name: "MaintenancePage"
    },
    defaultComponentPath: "Pages/Maintenance"
  },
  {
    params: {
      meta: {
        layout: "full",
        requiresAuth: true,
        requiredRoles: [],
        requiredModule: 0
      },
      path: "/pages/error/404",
      name: "pages/error/Error404"
    },
    defaultComponentPath: "Pages/Errors/Error404"
  },
  {
    params: {
      meta: {
        layout: "full",
        requiresAuth: true,
        requiredRoles: [],
        requiredModule: 0
      },
      path: "/pages/error/500",
      name: "pages/error/Error500"
    },
    defaultComponentPath: "Pages/Errors/Error500"
  },
  {
    params: {
      meta: {
        layout: "full",
        requiresAuth: true,
        requiredRoles: [],
        requiredModule: 0
      },
      path: "*",
      name: "PageNotFound"
    },
    defaultComponentPath: "Pages/Errors/Error404"
  }
];
