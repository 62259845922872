export default [
  {
    params: {
      path: '/login',
      name: 'LoginPage',
      meta: {
        layout: 'auth',
        requiresAuth: false,
      },
    },
    defaultComponentPath: 'Pages/Authentication/Login/Login',
  },
  {
    params: {
      path: '/forgot-password',
      name: 'ForgotPasswordPage',
      meta: {
        layout: 'auth',
        requiresAuth: false,
      },
    },
    defaultComponentPath: 'Pages/Authentication/ForgotPassword/ForgotPassword',
  },
  {
    params: {
      path: '/signup',
      name: 'SignupPage',
      meta: {
        layout: 'auth',
        requiresAuth: false,
      },
    },
    defaultComponentPath: 'Pages/Authentication/Signup/Signup',
  },
  {
    params: {
      path: '/active-account',
      name: 'ActiveAccount',
      meta: {
        layout: 'auth',
        requiresAuth: false,
      },
    },
    defaultComponentPath: 'Pages/Authentication/ActiveAccount/ActiveAccount',
  },
  {
    params: {
      path: '/lock-screen',
      name: 'LockScreenPage',
      meta: {
        layout: 'auth',
        requiresAuth: false,
      },
    },
    defaultComponentPath: 'Pages/Authentication/LockScreen/LockScreen',
  },
];
