import colors from 'vuetify/es5/util/colors';

export default {
  primary: {
    base: '#003a56',
    darken1: colors.cyan.darken3,
    lighten1: colors.cyan.lighten3,
  },
  secondary: {
    base: colors.orange.lighten1,
    darken1: colors.orange.darken3,
    lighten1: colors.orange.lighten3,
  },
  header: {
    color: '',
    classes: 'neu-glow',
    dark: false,
  },
  footer: {
    color: '',
    classes: 'neu-glow',
    dark: false,
  },
  primaryBgText: colors.shades.white,
  secondaryBgText: colors.shades.white,
  dark: false,
  semidark: false,
  rtl: false,
  locale: 'es'
};
