export default [
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin', 'talent_dashboard'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/admin-panel",
      name: "administrative/human-talent/AdminPanel"
    },
    defaultComponentPath: "HumanTalent/Dashboard",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin', 'admin_positions'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/positions",
      name: "administrative/human-talent/Positions"
    },
    defaultComponentPath: "HumanTalent/Positions",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin', 'admin_positions'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/positions/:positionId",
      name: "administrative/human-talent/PositionProfile"
    },
    defaultComponentPath: "HumanTalent/PositionProfile",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin', 'admin_positions'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/users/:userId",
      name: "administrative/human-talent/UserProfile"
    },
    defaultComponentPath: "HumanTalent/UserProfile",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin', 'admin_positions'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/profile",
      name: "administrative/human-talent/Profile"
    },
    defaultComponentPath: "HumanTalent/UserProfile",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin','admin_processes'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/manuals",
      name: "administrative/human-talent/Manuals"
    },
    defaultComponentPath: "HumanTalent/Manuals",
    navs: true
  },
// {
//   params: {
//     meta: {
//       requiresAuth: true,
//       requiredRoles: ['super_admin','admin', 'admin_processes'],
//       requiredModule: 2
//     },
//     path: "/administrative/human-talent/process",
//     name: "administrative/human-talent/Process"
//   },
//   defaultComponentPath: "HumanTalent/Process",
//   navs: true
// },

  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin', 'admin_positions'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/metrics",
      name: "administrative/human-talent/Metrics"
    },
    defaultComponentPath: "HumanTalent/MetricsManager",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin','admin', 'admin_positions'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/movement-reasons",
      name: "administrative/human-talent/MovementReasons"
    },
    defaultComponentPath: "HumanTalent/CRUDs/MovementReasons",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
      requiredRoles: ['super_admin','admin','admin_processes'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/versions",
      name: "administrative/human-talent/Versions"
    },
    defaultComponentPath: "HumanTalent/CRUDs/Versions",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
      requiredRoles: ['super_admin','admin'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/job-bank",
      name: "administrative/human-talent/JobBank"
    },
    defaultComponentPath: "HumanTalent/JobBank",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
      requiredRoles: ['super_admin','admin'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/positions/:positionOfferId/applications",
      name: "administrative/human-talent/PositionApplications"
    },
    defaultComponentPath: "HumanTalent/PositionApplications",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
      requiredRoles: ['super_admin','admin'],
        requiredModule: 2
      },
      path: "/administrative/human-talent/positions/:positionId/skills",
      name: "administrative/human-talent/PositionSkills",
      props:true,
    },
    defaultComponentPath: "HumanTalent/CRUDs/PositionSkills",
    navs: true,
  }
];
