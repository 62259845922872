import sidebar, { defaultActiveMenuStyles } from './sidebar';
import header from './header';
import footer from './footer';

export default {
  sidebar,
  defaultActiveMenuStyles,
  header,
  footer,
};
