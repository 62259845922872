export const availableLocale = [
  {
    text: 'Español',
    value: 'es',
    img: require( '@/assets/flags/spain.svg' ),
  },
  {
    text: 'English',
    value: 'en',
    img: require( '@/assets/flags/united-states.svg' ),
  },
];
export default {
  locale: 'es',
};
