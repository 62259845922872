export default [
  {
    params: {
      meta: {
        requiresAuth: true, requiredRoles: [  ], requiredModule: 2
      },
      path: '/business/expansion/createworkflow',
      name: 'business/expansion/createworkflow',
    },
    defaultComponentPath: 'Expansion/CreateWorkflow',
    navs: true,
  },
  {
    params: {
      meta: {
        requiresAuth: true, requiredRoles: [  ], requiredModule: 13
      },
      path: '/business/expansion/admin-panel',
      name: 'business/expansion/AdminPanel',
    },
    defaultComponentPath: 'Expansion/AdminPanel',
    navs: true,
  },
  {
    params: {
      meta: {
        requiresAuth: true, requiredRoles: [  ], requiredModule: 13
      },
      path: '/business/expansion/funnel-manager',
      name: 'business/expansion/FunnelManager',
    },
    defaultComponentPath: 'Expansion/FunnelManager',
    navs: true,
  },
]
