export default {
  userCompany: state => {
    return state.userCompany
  },
  userCurrentCompany: state => {
    return state.userCurrentCompany
  },
  userModules: state => {
    return state.userModules
  },
  userRoles: state => {
    return state.userRoles
  },
  timeZone: state => {
    return state.timeZone
  },
};
