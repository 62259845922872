import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueMeta from 'vue-meta'
import VueSweetalert2 from 'vue-sweetalert2'

import axios from '../api'

// Decrypt
import { decryptData } from "@/utils/encryption";


Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
})
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#003955',
  cancelButtonColor: '#ff7674',
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehviour(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return 0
    }
  },
})

function tokenValidation(authUser) {
  if (authUser && authUser.tokenSession) {
    if (decryptData(authUser.tokenSession)) return true
  }
  return false
}

function checkModule(to, authUser) {
  const assignedModules = []

  if (to.meta.requiredModule === 0) {
    return true
  } else if (authUser && authUser.userModules) {
    decryptData(authUser.userModules).forEach((module) => {
      assignedModules.push(module.id)
    })
    if (assignedModules.includes(to.meta.requiredModule)) {
      return true
    }
  }
  return false
}

function checkRoles(to, authUser) {
  const assignedRoles = []

  if (to.meta.requiredRoles.length > 0) {
    if (authUser && authUser.roles) {
      const decryptedRoles = decryptData(authUser.roles)

      if (decryptedRoles) {
        decryptedRoles.forEach((role) => {
          assignedRoles.push(role.name)
        })

        let counter = 0

        to.meta.requiredRoles.forEach((role) => {
          if (assignedRoles.includes(role)) {
            counter++
          }
        })
        if (counter >= 1) {
          return true
        }
      }
    }
  } else {
    return true
  }
  return false
}

router.beforeEach((to, from, next) => {
  NProgress.start()

  // Gets connection country
  let country = {}
  if (!localStorage.getItem('selectedCountry')) {
    axios.get('https://api.franchise-lead.com/api/get-location').then((response) => {
      country = response.data
      localStorage.setItem('selectedCountry', JSON.stringify(country))

      // Validate authentication

      if (to.path !== '/login' && to.meta.requiresAuth) {
        const authUser = JSON.parse(localStorage.getItem('vue-session-key')) // Gets user information
        const isValidToken = tokenValidation(authUser) // If token doesn't exist
        if (isValidToken) {
          const moduleOk = checkModule(to, authUser) // Check assigned modules
          if (moduleOk) {
            const rolesOk = checkRoles(to, authUser) // Check assigned roles
            if (rolesOk) {
              next({ props: { country: country } })
            } else {
              if (from.name) {
                next(false)
              } else {
                next({ path: '/login' })
              }
              Vue.swal({
                icon: 'error',
                title: 'Acceso restringido',
                html:
                  'Estas intentando ingresar a una sección a la cual no tienes permisos para acceder',
                showCancelButton: false,
                showConfirmButton: true,
              })
            }
          } else {
            next({
              path: from.path,
              props: { country: country, access: 'denied' },
            })
          }
        } else {
          next({ path: '/login', query: { redirect: to.path } })
        }
      } else {
        next({ props: { country: country } })
      }
    })
  } else {
    country = localStorage.getItem('selectedCountry')
    if (to.path !== '/login' && to.meta.requiresAuth === true) {
// console.log('to ', from)

      const authUser = JSON.parse(localStorage.getItem('vue-session-key')) // Gets user information
      const isValidToken = tokenValidation(authUser) // If token doesn't exist

      // console.log('token ', isValidToken)
      if (isValidToken) {
        const moduleOk = checkModule(to, authUser) // Check assigned modules

        // console.log('module ', moduleOk)
        if (moduleOk) {
          const rolesOk = checkRoles(to, authUser) // Check assigned roles

          // console.log('roles ', rolesOk)
          if (rolesOk) {
            next({ props: { country: country } })
          } else {
            if (from.name) {
              next(false)
            } else {
              next({ path: '/login' })
            }
            Vue.swal({
              icon: 'error',
              title: 'Acceso restringido',
              html:
                'Estas intentando ingresar a una sección a la cual no tienes permisos para acceder',
              showCancelButton: false,
              showConfirmButton: true,
            })
          }
        } else {
          if (from.path === '/') {
            next({
              path: '/login',
              props: { country: country, access: 'denied' },
            })
          } else {
            next({
              path: from.path,
              props: { country: country, access: 'denied' },
            })
          }
        }
      } else {
        next({ path: '/login', query: { redirect: to.path } })
      }
    } else {
      next({ props: { country: country } })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
  window.scrollTo(0, 0)
})

export default router
