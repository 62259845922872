export default [
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['evaluations_dashboard', 'admin',	'super_admin'],
        requiredModule: 17
      },
      path: "/business/supervision-control/admin-panel",
      name: "business/supervision-control/Dashboard",
      beforeEnter( to, from, next ) {
next();

// window.axios
//   .get("core-sync/graphics")
//   .then(() => {
//     next();
//   })
//   .catch(() => {
//     next(false);
//   });

      },
    },
    defaultComponentPath: "SupervisionControl/Dashboard",
    navs: true,

  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_evaluations', 'auditor_evaluations'],
        requiredModule: 17
      },
      path: "/business/supervision-control/evaluations",
      name: "business/supervision-control/Evaluations"
    },
    defaultComponentPath: "SupervisionControl/Evaluations",
    navs: true
  },
  {
  params: {
    meta: {
      requiresAuth: true,
      requiredRoles: ['super_admin', 'admin', 'admin_evaluations', 'auditor_evaluations', 'costumer_evaluations'],
      requiredModule: 17
    },
    path: "/business/supervision-control/assigned-evaluations",
    name: "business/supervision-control/GlobalAssignedEvaluations"
  },
  defaultComponentPath: "SupervisionControl/CRUDs/GlobalAssignedEvaluations",
  navs: true
  },
    {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_evaluations', 'auditor_evaluations', 'costumer_evaluations'],
        requiredModule: 17
      },
      path: "/business/supervision-control/assigned-evaluations/:assignedEvaluationId",
      name: "business/supervision-control/AssignedEvaluationDetails"
    },
    defaultComponentPath: "SupervisionControl/AuditCompany",
    navs: true
  },
    {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_supervisions'],
        requiredModule: 17
      },
      path: "/business/supervision-control/supervisions",
      name: "business/supervision-control/Supervisions"
    },
    defaultComponentPath: "SupervisionControl/Supervisions",
    navs: true
  },
    {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_documents'],
        requiredModule: 17
      },
      path: "/business/supervision-control/documents",
      name: "business/supervision-control/Documents"
    },
    defaultComponentPath: "SupervisionControl/DocumentsManager",
    navs: true
  },

  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 17
      },
      path: "/business/supervision-control/company",
      name: "business/supervision-control/CompanyDashboard"
    },
    defaultComponentPath: "SupervisionControl/CompanyDashboard",
    navs: true
  },
      {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 17
      },
      path: "/business/supervision-control/company/documents",
      name: "business/supervision-control/CompanyDocuments"
    },
    defaultComponentPath: "SupervisionControl/CRUDs/Documents",
    navs: true
  },
    {
    params: {
      meta: {
        requiresAuth: true,
         requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 17
      },
      path: "/business/supervision-control/company/assigned-evaluations",
      name: "business/supervision-control/company/AssignedEvaluations"
    },
    defaultComponentPath: "SupervisionControl/CRUDs/GlobalAssignedEvaluations",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 17
      },
      path: "/business/supervision-control/company/observations",
      name: "business/supervision-control/CompanyObservations"
    },
    defaultComponentPath: "SupervisionControl/CRUDs/Observations",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 17
      },
      path: "/business/supervision-control/company/supervisions",
      name: "business/supervision-control/CompanySupervisions"
    },
    defaultComponentPath: "SupervisionControl/Supervisions",
    navs: true
  },
  // {
  //   params: {
  //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['super_admin', 'admin'],
  //       requiredModule: 17
  //     },
  //     path: "/business/supervision-control/evaluation/:companyId",
  //     name: "business/supervision-control/Evaluation"
  //   },
  //   defaultComponentPath: "SupervisionControl/Evaluation",
  //   navs: true
  // },
  // {
    //   params: {
      //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['super_admin', 'admin'],
  //       requiredModule: 17
  //     },
  //     path: "/business/supervision-control/penalties",
  //     name: "business/supervision-control/Penalties"
  //   },
  //   defaultComponentPath: "SupervisionControl/CRUDs/Penalties",
  //   navs: true
  // },
  // {
  //   params: {
  //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['super_admin', 'admin'],
  //       requiredModule: 17
  //     },
  //     path: "/business/supervision-control/company/penalties",
  //     name: "business/supervision-control/CompanyPenalties"
  //   },
  //   defaultComponentPath: "SupervisionControl/CRUDs/CompanyPenalties",
  //   navs: true
  // },
  // {
  //   params: {
  //     meta: {
    //       requiresAuth: true,
    //       requiredRoles: ['super_admin', 'admin'],
    //       requiredModule: 17
    //     },
    //     path: "/business/supervision-control/observations",
    //     name: "business/supervision-control/Observations"
    //   },
  //   defaultComponentPath: "SupervisionControl/CRUDs/Observations",
  //   navs: true
  // },
];
