export default {
  initialiseStore(state) {
    if (localStorage.getItem("lang")) {
      state.locale = localStorage.getItem("lang");
    }
if (localStorage.getItem("timeZone")) {
  state.timeZone = localStorage.getItem("timeZone");
} else {
  localStorage.setItem(
    "timeZone",
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  state.timeZone = localStorage.getItem("timeZone");
}

    if (JSON.parse(localStorage.getItem("vue-session-key"))) {
      if (JSON.parse(localStorage.getItem("vue-session-key")).userCompany) {
        state.userCompany = JSON.parse(
          localStorage.getItem("vue-session-key")
        ).userCompany;
      } else {
        state.userCompany = "";
      }
      if (
        JSON.parse(localStorage.getItem("vue-session-key")).userCurrentCompany
      ) {
        state.userCurrentCompany = JSON.parse(
          localStorage.getItem("vue-session-key")
        ).userCurrentCompany;
      } else {
        state.userCurrentCompany = "";
      }
      if (JSON.parse(localStorage.getItem("vue-session-key")).userModules) {
        state.userModules = JSON.parse(
          localStorage.getItem("vue-session-key")
        ).userModules;
      } else {
        state.userModules = "";
      }
      if (JSON.parse(localStorage.getItem("vue-session-key")).roles) {
        state.userRoles = JSON.parse(
          localStorage.getItem("vue-session-key")
        ).roles;
      } else {
        state.userRoles = "";
      }
    }
  },
  setUserCompany: (state, company) => {
    state.userCompany = company;
    state.userCurrentCompany = company;
  },
  updateUserCurrentCompany: (state, company) => {
    state.userCurrentCompany = company;
    let session = {};
    if (
      localStorage.getItem("vue-session-key") &&
      JSON.parse(localStorage.getItem("vue-session-key")).userCurrentCompany
    ) {
      session = JSON.parse(localStorage.getItem("vue-session-key"));
      session.userCurrentCompany = company;
      localStorage.setItem("vue-session-key", JSON.stringify(session));
    }
    location.reload();
  },
  updateUserModules: (state, modules) => {
    state.userModules = modules;
  },
  updateUserRoles: (state, roles) => {
    state.userRoles = roles;
  }
};
