import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import axios from "./api";
import router from "./router";
import store from "./store";
import DatetimePicker from "vuetify-datetime-picker";
import { createI18n } from "@/i18n/index";
import VModal from "vue-js-modal";
import Multiselect from "vue-multiselect";
import VueSweetalert2 from "vue-sweetalert2";
import Help from "@/components/App/Help";
import VueSession from "vue-session";
import FlowChart from "flowchart-vue";
import VueMask from "v-mask";
import VueCountdownTimer from "vuejs-countdown-timer";
import Vue2Editor from "vue2-editor";
import SmartBreadcrumbs from "@/components/App/SmartBreadcrumbs";
import VueApexCharts from "vue-apexcharts";
import VueEasyLightbox from "vue-easy-lightbox";
import "vue-js-modal/dist/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "./sass/main.scss";
import VueSocialSharing from "vue-social-sharing";
import VueQR from "vue-qr";

Vue.use(VueSocialSharing);

Vue.use(Vue2Editor);
Vue.use(VueMask);
Vue.use(DatetimePicker);
Vue.use(FlowChart);
Vue.use(VueEasyLightbox);
Vue.use(VueApexCharts);
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#003955",
  cancelButtonColor: "#ff7674"
});
Vue.use(VueCountdownTimer);
Vue.use(VModal, {
  componentName: "modal",
  dynamicDefault: {
    adaptive: true,
    resizable: true,
    height: "auto"
  }
});
Vue.use(VueSession, {
  persist: true
});

Vue.component("multiselect", Multiselect);
Vue.component("smart-breadcrumbs", SmartBreadcrumbs);
Vue.component("help", Help);
Vue.component("apexchart", VueApexCharts);
Vue.component("multiselect", Multiselect);
Vue.component("qr-code", VueQR);

axios.defaults.timeout = 60000;
axios.defaults.baseURL = process.env.VUE_APP_CLOUD_API;
window.$ = require("jquery");
window.JQuery = require("jquery");
window.axios = axios;
window.swal = VueSweetalert2;

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    if (
      response &&
      (response.status === 200 ||
        response.status === 201 ||
        response.status === 204)
    ) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400: {
          let contentMessage = "";
          let arrayOfErrors = [];
          if (error.response.data.errors) {
            contentMessage =
              '<p>Por favor, verifique la información suministrada e intente nuevamente</p><dl style="text-align: justify">';
            arrayOfErrors = Object.entries(error.response.data.errors);
            arrayOfErrors.forEach((error) => {
              contentMessage =
                contentMessage +
                '<dt style="text-transform: capitalize">' +
                error[0] +
                "</dt>";
              error[1].forEach((item) => {
                contentMessage = contentMessage + "<dd>- " + item + "</dd >";
              });
            });
            contentMessage = contentMessage + "</dl>";
          } else {
            contentMessage = "La información sumistrada es inválida";
          }
          Vue.swal({
            icon: "error",
            title: error.response.data.message
              ? error.response.data.message
              : "Ocurrió un error",
            html: contentMessage,
            showCancelButton: false,
            showConfirmButton: true
          });
          throw new Error(error);
        }

        case 401: {
          if (error.response.config.url === "auth/login") {
            Vue.swal({
              icon: "error",
              title: "Datos inválidos",
              html: "La combinación de correo electrónico y contraseña es incorrecta",
              showCancelButton: false,
              showConfirmButton: true
            });
          } else if (
            localStorage.getItem("vue-session-key") &&
            JSON.parse(localStorage.getItem("vue-session-key")).tokenSession
          ) {
            Vue.swal({
              icon: "error",
              title: "Acceso restringido",
              html: "Tu sesión ha caducado o no tienes permisos suficientes para continuar.",
              showCancelButton: false,
              showConfirmButton: true
            });
            localStorage.removeItem("vue-session-key");
            router.push("/");
          }

          throw new Error(error);
        }

        case 422: {
          let contentMessage = "";
          let arrayOfErrors = [];
          if (error.response.data.errors) {
            contentMessage =
              '<p>Por favor, verifique la información suministrada e intente nuevamente</p><dl style="text-align: justify">';
            arrayOfErrors = Object.entries(error.response.data.errors);
            arrayOfErrors.forEach((error) => {
              contentMessage =
                contentMessage +
                '<dt style="text-transform: capitalize">' +
                error[0] +
                "</dt>";
              error[1].forEach((item) => {
                contentMessage = contentMessage + "<dd>- " + item + "</dd >";
              });
            });
            contentMessage = contentMessage + "</dl>";
          } else {
            contentMessage = "La información sumistrada es inválida";
          }
          Vue.swal({
            icon: "error",
            title: error.response.data.message
              ? error.response.data.message
              : "Ocurrió un error",
            html: contentMessage,
            showCancelButton: false,
            showConfirmButton: true
          });
          throw new Error(error);
        }
        default:
          break;
      }
      return Promise.reject(error);
    }
  }
);

Vue.prototype.$axios = axios;

const i18n = createI18n(store.state.translation.locale).i18n;
Vue.config.productionTip = false;

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
  render: (h) => h(App)
}).$mount("#app");
