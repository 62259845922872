var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"transparent mx-3",attrs:{"max-width":"200"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"neu-glow",attrs:{"fab":"","small":""}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("color_lens")])],1)]}}]),model:{value:(_vm.primaryColorMenu),callback:function ($$v) {_vm.primaryColorMenu=$$v},expression:"primaryColorMenu"}},[_c('vuse-color-picker',{attrs:{"dismissible":""},on:{"changed":_vm.setPrimaryColor,"closed":function($event){_vm.primaryColorMenu = false}}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"subtitle"},[_vm._v("Primary color")])])],1),_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"neu-glow",attrs:{"fab":"","small":""}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("color_lens")])],1)]}}]),model:{value:(_vm.secondaryColorMenu),callback:function ($$v) {_vm.secondaryColorMenu=$$v},expression:"secondaryColorMenu"}},[_c('vuse-color-picker',{attrs:{"dismissible":""},on:{"changed":_vm.setSecondaryColor,"closed":function($event){_vm.secondaryColorMenu = false}}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"subtitle"},[_vm._v("Secondary color")])])],1),_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"neu-glow",attrs:{"fab":"","small":""}},on),[_c('v-icon',{attrs:{"color":_vm.header.color}},[_vm._v("color_lens")])],1)]}}]),model:{value:(_vm.headerBgMenu),callback:function ($$v) {_vm.headerBgMenu=$$v},expression:"headerBgMenu"}},[_c('vuse-color-picker',{attrs:{"reset-neu":"","dismissible":""},on:{"changed":function($event){return _vm.handleNavTheme($event, 'setHeaderColor')},"closed":function($event){_vm.headerBgMenu = false}}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"subtitle"},[_vm._v("Header")])])],1),_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"neu-glow",attrs:{"fab":"","small":""}},on),[_c('v-icon',{attrs:{"color":_vm.footer.color}},[_vm._v("color_lens")])],1)]}}]),model:{value:(_vm.footerBgMenu),callback:function ($$v) {_vm.footerBgMenu=$$v},expression:"footerBgMenu"}},[_c('vuse-color-picker',{attrs:{"reset-neu":"","dismissible":""},on:{"changed":function($event){return _vm.handleNavTheme($event, 'setFooterColor')},"closed":function($event){_vm.footerBgMenu = false}}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"subtitle"},[_vm._v("Footer")])])],1),_c('div',[_c('v-switch',{attrs:{"label":"Dark Theme"},model:{value:(_vm.isDarkMod),callback:function ($$v) {_vm.isDarkMod=$$v},expression:"isDarkMod"}}),_c('v-switch',{attrs:{"label":"Semi Dark Theme"},model:{value:(_vm.isSemiDarkMod),callback:function ($$v) {_vm.isSemiDarkMod=$$v},expression:"isSemiDarkMod"}}),_c('v-switch',{attrs:{"label":"RTL"},model:{value:(_vm.isRtl),callback:function ($$v) {_vm.isRtl=$$v},expression:"isRtl"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }