export const SET_SIDENAV_VISIBILITY = 'SET_SIDENAV_VISIBILITY';
export const SET_SIDENAV_MINI_VARIANT = 'SET_SIDENAV_MINI_VARIANT';
export const SET_SIDENAV_ACTIVE_MENU_STYLE = 'SET_SIDENAV_ACTIVE_MENU_STYLE';
export const SET_SIDENAV_POSITION_TO_RIGHT = 'SET_SIDENAV_POSITION_TO_RIGHT';

export const SET_HEADER_VISIBILITY = 'SET_HEADER_VISIBILITY';
export const SET_HEADER_CLIPPED_OVER = 'SET_HEADER_CLIPPED_OVER';
export const UPDATE_HEADER_SETTING = 'UPDATE_HEADER_SETTING';

export const SET_LOCALE = 'SET_LOCALE';

export const SET_FOOTER_VISIBILITY = 'SET_FOOTER_VISIBILITY';
export const SET_APP_FOOTER = 'SET_APP_FOOTER';
export const SET_FIXED_FOOTER = 'SET_FIXED_FOOTER';
export const SET_INSET_FOOTER = 'SET_INSET_FOOTER';
export const SET_ABSOLUTE_FOOTER = 'SET_ABSOLUTE_FOOTER';
export const SET_PADLESS_FOOTER = 'SET_PADLESS_FOOTER';

export const SET_PRIMARY_COLOR = 'SET_PRIMARY_COLOR';
export const SET_SECONDARY_COLOR = 'SET_SECONDARY_COLOR';
export const SET_HEADER_COLOR = 'SET_HEADER_COLOR';
export const SET_FOOTER_COLOR = 'SET_FOOTER_COLOR';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_SEMI_DARK_MODE = 'SET_SEMI_DARK_MODE';
export const SET_RTL = 'SET_RTL';
