export default [
  {
    params: {
      meta: {
        requiresAuth: false,
        requiredRoles: [],
        requiredModule: 0
      },
      path: "/embed/jobs/:companyId",
      name: "embed/Jobs"
    },
    defaultComponentPath: "Embedded/Jobs",
    navs: false,
  }
];
