import CryptoJS from "crypto-js";

export const encryptData = (data, salt) => {
  let key = "";
  salt && salt !== undefined
    ? (key = salt)
    : (key = process.env.VUE_APP_ENCRYPTION_KEY);

  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const decryptData = (ciphertext, salt) => {
  let key = "";
  salt && salt !== undefined
    ? (key = salt)
    : (key = process.env.VUE_APP_ENCRYPTION_KEY);

  const bytes = CryptoJS.AES.decrypt(ciphertext, key);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const base64Encode = (text) => {
  const encodedText = CryptoJS.enc.Utf8.parse(text);
  return CryptoJS.enc.Base64.stringify(encodedText);
};

export const base64Decode = (text) => {
  const encodedText = CryptoJS.enc.Base64.parse(text);
  return CryptoJS.enc.Utf8.stringify(encodedText);
};
