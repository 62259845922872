<template>
  <v-breadcrumbs :items="items">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="{ href: item.href ? item.href : '', name: item.name ? item.name : '' }"
        v-if="!item.query && !item.params"
        :disabled="item.disabled"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
        :to="{
          name: item.name,
          params: item.params ? item.params : '',
          query: item.query ? item.query : '',
        }"
        :disabled="item.disabled"
        v-else
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
  export default {
    name: 'SmartBreadcrumbs',
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
  }
</script>
<style lang="scss">
  .v-breadcrumbs__item {
    font-size: 12px;
  }
  .v-breadcrumbs li:nth-child(even) {
    padding: 0 5px;
  }
  .v-breadcrumbs {
    padding: 5px 10px !important;
  }
</style>
