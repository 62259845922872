<template>
  <v-btn icon :small="!xSmall" :x-small="xSmall" @click="getHelp()" class="help">
    <v-icon>mdi-information-outline</v-icon>
    <v-dialog
      v-model="modal"
      :name="'helpModal' + helpId"
      :max-width="widthDialog"
      style="z-index: 1000"
    >
      <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text>
        <v-tabs-slider />

        <v-tab href="#tab-1">
          Ayuda
          <v-icon>mdi-help-rhombus-outline</v-icon>
        </v-tab>
        <v-tab
          href="#tab-2"
          v-if="
            (help.practices && help.practices != 'null') ||
            (help.images && help.images.length > 0) ||
            (help.documents && help.documents.length > 0)
          "
        >
          Visuales
          <v-icon>mdi-image-multiple-outline</v-icon>
        </v-tab>
      </v-tabs>
      <v-sheet style="overflow-x: hidden">
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card
              flat
              class="py-3 pa-sm-5 d-flex flex-column justify-center editor-container"
              v-if="help != {}"
            >
              <h2 class="mb-4 text-h5 text-center text--primary">{{ help.name }}</h2>
              <vue-editor
                v-model="help.info"
                disabled
                :editor-toolbar="customToolbarVideo"
              />
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <!-- VIDEOS -->
            <v-card
              flat
              class="ma-2 ma-sm-5 video-container"
              height="50vh"
              v-if="help.practices"
            >
              <vue-editor
                disabled
                id="videoVueEditor"
                v-model="help.practices"
                :editor-toolbar="customToolbarVideo"
              />
            </v-card>

            <!-- IMAGES -->
            <v-card
              v-if="help.images && help.images.length > 0"
              flat
              class="ma-2 ma-sm-5 d-flex flex-column"
            >
              <span
                class="mx-auto mt-4 mb-n4 caption text--secondary"
                v-if="$vuetify.breakpoint.xs"
                >Haz click en la imagen para hacer zoom</span
              >
              <v-carousel
                cycle
                height="300"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item v-for="(image, i) in carouselImages" :key="i">
                  <v-img
                    :src="image.src"
                    contain
                    aspect-ratio="1"
                    alt=""
                    class="carousel__image"
                    height="300"
                    @click="showImg(i)"
                  >
                    <!-- <v-btn
                      small
                      class="btn--fullscreen"
                      depressed

                    >
                      <v-icon> mdi-fullscreen </v-icon>
                    </v-btn> -->
                  </v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>
            <v-card
              flat
              class="ma-2 ma-sm-5 pa-2 pa-sm-5"
              v-if="help.documents && help.documents.length > 0"
            >
              <h4 class="mb-4 text-h5 text-center">Archivos Complementarios</h4>
              <v-row justify="center">
                <v-col
                  v-for="(doc, index) in help.documents"
                  :key="doc.index"
                  class="d-flex justify-center align-center"
                  cols="12"
                  sm="6"
                >
                  <v-btn
                    fab
                    title="Ver Archivo"
                    :href="doc.route"
                    target="_blank"
                    rel="nooperner"
                    class="mr-2"
                  >
                    <v-icon x-large>
                      {{ docsIcons(doc.route) }}
                    </v-icon>
                  </v-btn>
                  Documento {{ index + 1 }}
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-row class="ma-5" :width="'100%'" justify="center">
          <v-btn text color="teal accent-4" @click="modal = false"> Cerrar </v-btn>
        </v-row>
      </v-sheet>
    </v-dialog>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="carouselImages"
      :index="lightboxIndex"
      @hide="handleHideLightbox"
    />
  </v-btn>
</template>
<script>
  export default {
    components: {},
    props: {
      helpId: {
        type: Number,
        required: true,
      },
      xSmall: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => {
      return {
        tab: null,
        help: {},
        carouselImages: [],
        modal: false,
        customToolbarVideo: [[]],
        visible: false,
        lightboxIndex: 100,
      }
    },
    computed: {
      widthDialog() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '60%'
      },
    },
    methods: {
      showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHideLightbox() {
        this.visible = false
      },
      getHelp() {
        this.modal = true
        if (!this.help.id) {
          this.$axios
            .get(process.env.VUE_APP_CORE_API + 'helps/' + this.helpId)
            .then((response) => {
              this.help = response.data
              Object.assign(this.help, {
                documents: this.help.uploads.filter((file) => file.type === 'document'),
              })
              Object.assign(this.help, {
                images: this.help.uploads.filter((file) => file.type === 'image'),
              })
              this.getCarouselImages(this.help.images)
              this.indexHelp++
            })
        }
      },
      getCarouselImages(imageArray) {
        imageArray.forEach((image) => {
          this.carouselImages.push({
            src: image.route,
          })
        })
      },
      docsIcons(text) {
        const indicator = text.split('.').pop()
        let icon
        switch (indicator) {
          case 'pdf':
            icon = 'mdi-file-pdf-box'
            break
          case 'doc':
            icon = 'mdi-microsoft-word'
            break
          case 'docx':
            icon = 'mdi-microsoft-word'
            break
          case 'xls':
            icon = 'mdi-microsoft-excel'
            break
          case 'xlsx':
            icon = 'mdi-microsoft-excel'
            break
          case 'ppt':
            icon = 'mdi-microsoft-powerpoint'
            break
          case 'pptx':
            icon = 'mdi-microsoft-powerpoint'
            break

          default:
            icon = 'mdi-folder'
            break
        }
        return icon
      },
    },
  }
</script>
<style lang="scss">
  @import '~vue2-editor/dist/vue2-editor.css';

  .help {
    .carousel__image {
      cursor: zoom-in;
    }
    .v-btn--icon.v-size--default {
      height: 25px;
      width: 25px;
    }
    .v-btn--fab.v-size--small {
      height: 20px;
      width: 20px;
    }
    .vm--container {
      display: flex;
      //height: auto;
    }
    .v-card__actions {
      align-items: center;
      justify-content: center;
    }
    .ql-toolbar.ql-toolbar.ql-snow {
      border: none;
    }
  }
  .editor-container,
  .video-container {
    .quillWrapper {
      .ql-toolbar.ql-snow {
        display: none;
      }
      .ql-container.ql-snow {
        border: none;
      }
      height: inherit;
    }
    #videoVueEditor {
      .ql-editor {
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        max-height: 350px;
      }
      .ql-video {
        width: 80%;
      }
      p {
        display: none;
      }
    }
  }
</style>
