import navigations from './navigations';
import locale from './locale';
import theme from './theme';

export const configSwatch = theme;

export default {
  navigations,
  locale,
  theme,
};
