import axios from "axios";
import axiosRetry from "axios-retry";

// Decrypt
import { decryptData } from "@/utils/encryption";

axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 0;
  }
});

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    let token = "";
    const ciphertext = localStorage.getItem("vue-session-key")
      ? JSON.parse(localStorage.getItem("vue-session-key"))
      : "";
    if (ciphertext.tokenSession && ciphertext.tokenSession !== "") {
      token = decryptData(ciphertext.tokenSession);
      if (!token) {
        ciphertext.tokenSession = "";
        localStorage.setItem("vue-session-key", ciphertext);
        location.href("/login");
      }
    }
    config.baseURL = process.env.VUE_APP_CLOUD_API;
    config.headers.get.Accept = "application/json";
    config.headers.get["Content-Type"] = "application/json";
    config.headers.get["X-Requested-With"] = "XMLHttpRequest";
    config.headers.get["Access-Control-Allow-Origin"] = "*";
    config.headers.get["Access-Control-Allow-Methods"] =
      "GET, POST, PATCH, PUT, DELETE, OPTIONS";
    config.headers.get["Access-Control-Allow-Headers"] =
      "Origin, Content-Type, X-Requested-With, Accept";
    config.headers.post.Accept = "application/json";
    config.headers.post["Content-Type"] = "application/json";
    config.headers.post["X-Requested-With"] = "XMLHttpRequest";
    config.headers.post["Access-Control-Allow-Origin"] = "*";
    config.headers.post["Access-Control-Allow-Methods"] =
      "GET, POST, PATCH, PUT, DELETE, OPTIONS";
    config.headers.post["Access-Control-Allow-Headers"] =
      "Origin, Content-Type, X-Requested-With, Accept";
    config.headers.common.Authorization = "Bearer " + token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
