export default [
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'elearning_dashboard', 'admin_learning'],
        requiredModule: 14
      },
      path: "/business/elearning/admin-panel",
      name: "business/elearning/AdminPanel"
    },
    defaultComponentPath: "Elearning/AdminPanel",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin',],
        requiredModule: 14
      },
      path: "/business/elearning/user-panel",
      name: "business/elearning/UserPanel"
    },
    defaultComponentPath: "Elearning/UserPanel",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_courses'],
        requiredModule: 14
      },
      path: "/business/elearning/courses",
      name: "business/elearning/Courses"
    },
    defaultComponentPath: "Elearning/CoursesAndLessons",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_learning'],
        requiredModule: 14
      },
      path: "/business/elearning/traning-performance",
      name: "business/elearning/TrainingPerformance"
    },
    defaultComponentPath: "Elearning/TrainingPerformance",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 14
      },
      path: "/business/elearning/user-panel/courses",
      name: "business/elearning/UserCourses"
    },
    defaultComponentPath: "Elearning/UserCourses",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 14
      },
      path: "/business/elearning/user-panel/courses/:courseId",
      name: "business/elearning/UserCourseDetail"
    },
    defaultComponentPath: "Elearning/UserCourseDetail",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 14
      },
      path: "/business/elearning/user-panel/lessons/:lessonId",
      name: "business/elearning/UserLessonDetail",
    },
    defaultComponentPath: "Elearning/UserLessonDetail",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'user'],
        requiredModule: 14
      },
      path: "/business/elearning/user-panel/exam/:examId",
      name: "business/elearning/UserExam"
    },
    defaultComponentPath: "Elearning/UserExam",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_courses'
],
        requiredModule: 14
      },
      path: "/business/elearning/createcourse",
      name: "business/elearning/createcourse"
    },
    defaultComponentPath: "Elearning/CreateCourse",
    navs: true
  },
  // {
  //   params: {
  //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['super_admin', 'admin','admin_courses'],
  //       requiredModule: 14
  //     },
  //     path: "/business/elearning/coursemanager",
  //     name: "business/elearning/coursemanager"
  //   },
  //   defaultComponentPath: "Elearning/CourseManager",
  //   navs: true
  // },
  // {
  //   params: {
  //     meta: {
  //       requiresAuth: true,
  //       requiredRoles: ['super_admin', 'admin',],
  //       requiredModule: 14
  //     },
  //     path: "/business/elearning/courses-crud",
  //     name: "business/elearning/courses-crud"
  //   },
  //   defaultComponentPath: "Elearning/CRUDs/CoursesList",
  //   navs: true
  // },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_courses'],
        requiredModule: 14
      },
      path: "/business/elearning/courses/:courseId/exam",
      name: "business/elearning/ExamQuestions"
    },
    defaultComponentPath: "Elearning/CRUDs/ExamQuestions",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_courses'],
        requiredModule: 14
      },
      path: "/business/elearning/courses/:courseId/lessons",
      name: "business/elearning/CourseLessons"
    },
    defaultComponentPath: "Elearning/CRUDs/CourseLessons",
    navs: true
  },
  {
    params: {
      meta: {
        requiresAuth: true,
        requiredRoles: ['super_admin', 'admin', 'admin_courses'],
        requiredModule: 14
      },
      path: "/business/elearning/questions/:questionId/answers",
      name: "business/elearning/QuestionAnswers"
    },
    defaultComponentPath: "Elearning/CRUDs/QuestionAnswers",
    navs: true
  }
];
